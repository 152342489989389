import React, { useEffect, useState, useRef } from "react";
import { marked } from "marked";

const Message = ({ type, text }) => {
  
  const [visible, setVisible] = useState(type === "user");
  const [displayedText, setDisplayedText] = useState("");
  const messageEndRef = useRef(null);

  // Ensure text is a valid string
  const safeText = typeof text === "string" ? text : "";

  // Clean up the text if it contains "no information available" placeholder
  const processedText = safeText.includes("There is no information available regarding")
    ? "I'm sorry, I couldn't find the information you're looking for. Please feel free to reach out directly at Email: contact@TalentBridge.fi or Phone: +358 40 571 0112 for more assistance."
    : safeText;

  useEffect(() => {
    setVisible(true);
    setDisplayedText(processedText); // Set the full processed text immediately
  }, [type, processedText]);

  // Scroll to the latest message when displayedText updates
  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [displayedText]);

  // Convert Markdown to HTML and set links to open in a new tab
  const formattedText = marked(displayedText, {
    renderer: new marked.Renderer(),
    gfm: true, // Use GitHub-flavored Markdown for better formatting support
  });

  // Modify all links to open in a new tab and apply link color
  const styledText = formattedText.replace(
    /<a href="(.*?)">(.*?)<\/a>/g,
    '<a href="$1" target="_blank" rel="noopener noreferrer" style="color:blue;text-decoration:underline;">$2</a>'
  );

  // Don't render if there's no text to display
  if (!visible || !processedText) return null;

  return (
    safeText !== "error" && (
      <div
        className={`flex mb-4 ${type === "user" ? "justify-end" : "justify-start"}`}
        style={{ justifyContent: type === "user" ? "right" : "left" }}
      >
        <div
          className={`rounded-lg shadow-md ${
            type === "user" ? "bg-blue-500 text-white" : "bg-gray-100 text-black"
          }`}
          style={{
            width: "70%",
            padding: "0.5rem 1rem",
            borderRadius: "15px",
            borderBottomRightRadius: type === "user" ? "0px" : "15px",
            borderBottomLeftRadius: type === "bot" ? "0px" : "15px",
            backgroundColor: type === "user" ? "#1a73e8" : "#f3f1f1",
            color: type === "user" ? "white" : "black",
          }}
        >
          {type === "bot" ? (
            // Render the parsed HTML with modified links
            <div dangerouslySetInnerHTML={{ __html: styledText }} />
          ) : (
            <p>{safeText}</p>
          )}
          <div ref={messageEndRef} />
        </div>
      </div>
    )
  );
};

export default Message;
