// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Container for dropdown */
.user-dropdown {
    position: absolute;
    right: 0;
    top: 67px;
    width: 90px;
    background: white;
    border: 1px solid #ddd;
    /* border-radius: 8px; */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 100;
  }
  
  /* Dropdown list styles */
  .user-dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  /* Dropdown list items */
  .user-dropdown li {
    padding: 10px 15px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  /* Hover effect for options */
  .user-dropdown li:hover {
    background: #f0f0f0;
  }
  
  /* Logout button styles */
  .user-dropdown .logout {
    color: red;
    font-weight: bold;
  }
  
  .user-dropdown .logout:hover {
    background: red;
    color: white;
  }
  
  /* User profile image */
  .user-profile-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  /* Hover effect for user profile image */
  .user-profile-img:hover {
    transform: scale(1.1);
  }
  `, "",{"version":3,"sources":["webpack://./src/Chat/Header/header.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,WAAW;IACX,iBAAiB;IACjB,sBAAsB;IACtB,wBAAwB;IACxB,0CAA0C;IAC1C,YAAY;EACd;;EAEA,yBAAyB;EACzB;IACE,gBAAgB;IAChB,UAAU;IACV,SAAS;EACX;;EAEA,wBAAwB;EACxB;IACE,kBAAkB;IAClB,eAAe;IACf,2BAA2B;EAC7B;;EAEA,6BAA6B;EAC7B;IACE,mBAAmB;EACrB;;EAEA,yBAAyB;EACzB;IACE,UAAU;IACV,iBAAiB;EACnB;;EAEA;IACE,eAAe;IACf,YAAY;EACd;;EAEA,uBAAuB;EACvB;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,0BAA0B;EAC5B;;EAEA,wCAAwC;EACxC;IACE,qBAAqB;EACvB","sourcesContent":["/* Container for dropdown */\n.user-dropdown {\n    position: absolute;\n    right: 0;\n    top: 67px;\n    width: 90px;\n    background: white;\n    border: 1px solid #ddd;\n    /* border-radius: 8px; */\n    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);\n    z-index: 100;\n  }\n  \n  /* Dropdown list styles */\n  .user-dropdown ul {\n    list-style: none;\n    padding: 0;\n    margin: 0;\n  }\n  \n  /* Dropdown list items */\n  .user-dropdown li {\n    padding: 10px 15px;\n    cursor: pointer;\n    transition: background 0.3s;\n  }\n  \n  /* Hover effect for options */\n  .user-dropdown li:hover {\n    background: #f0f0f0;\n  }\n  \n  /* Logout button styles */\n  .user-dropdown .logout {\n    color: red;\n    font-weight: bold;\n  }\n  \n  .user-dropdown .logout:hover {\n    background: red;\n    color: white;\n  }\n  \n  /* User profile image */\n  .user-profile-img {\n    width: 30px;\n    height: 30px;\n    border-radius: 50%;\n    cursor: pointer;\n    transition: transform 0.2s;\n  }\n  \n  /* Hover effect for user profile image */\n  .user-profile-img:hover {\n    transform: scale(1.1);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
