import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Define Initial State
const initialState = {
  user: null,
  token: null,
  loading: false,
  error: null,
  showResume: false,
  editResume: false,
  viewPrintableResume: false,
  dropdownOpen: false,
  showSuccessMessage: false,
  showDelete : false,
  educationMessages : '',
  botResponseEducation : '',
};

// Async Thunk for Sign In
export const signIn = createAsyncThunk(
  'auth/signIn',
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await axios.post(
        'https://api.susan.fi/api/signin',
        { email, password },
        { headers: { 'Content-Type': 'application/json' }, withCredentials: true }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Sign-in failed');
    }
  }
);

// Async Thunk for Sign Up
export const signUp = createAsyncThunk(
  'auth/signUp',
  async ({ name, email, password }, thunkAPI) => {
    try {
      const response = await axios.post(
        'https://api.susan.fi/api/signup',
        { name, email, password },
        { headers: { 'Content-Type': 'application/json' }, withCredentials: true }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Sign-up failed');
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signOut: (state) => {
      state.user = null;
      state.token = null;
      state.resume = null;
      state.error = null;
      state.showResume = false;
      state.editResume = false;
      state.viewPrintableResume = false;
      state.dropdownOpen = false;
      state.showSuccessMessage = false;
      state.showDelete = false;
      localStorage.removeItem("persist:auth");
    },
    setUserData: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setResumeData: (state, action) => {
      state.resume = action.payload.resume;
    },
    setShowResume: (state, action) => {
      state.showResume = action.payload;
    },
    setEditResume: (state, action) => {
      state.editResume = action.payload;
    },
    setViewPrintableResume: (state, action) => {
      state.viewPrintableResume = action.payload;
    },
    setDropdownOpen: (state, action) => {
      state.dropdownOpen = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.showSuccessMessage = action.payload;
    },
    setShowDelete : (state, action) => {
      state.showDelete = action.payload;
    },
    setEducationMessages : (state, action) => {
      state.educationMessages = action.payload;
    },
    setBotResponseEducation : (state, action) => {
      state.botResponseEducation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.showSuccessMessage = true;
        // Automatically hide message after 3 seconds
        setTimeout(() => {
          state.showSuccessMessage = false;
        }, 3000);
      })
      .addCase(signIn.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(signUp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.showSuccessMessage = true;
        // Automatically hide message after 3 seconds
        setTimeout(() => {
          state.showSuccessMessage = false;
        }, 3000);
      })
      .addCase(signUp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { signOut, setUserData, setResumeData, setShowResume, setEditResume, setViewPrintableResume, setDropdownOpen, setSuccessMessage, setShowDelete, setEducationMessages, setBotResponseEducation } = authSlice.actions;
export default authSlice.reducer;