// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  padding: 0px;
 /* font-family:"Lato, sans-serif"; */
  margin: 0px;
  box-sizing: border-box;
  background-color: #0e3350;
  height: auto;
}
a {
  color: #2467a3;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;CACb,oCAAoC;EACnC,WAAW;EACX,sBAAsB;EACtB,yBAAyB;EACzB,YAAY;AACd;AACA;EACE,cAAc;AAChB","sourcesContent":["body {\n  padding: 0px;\n /* font-family:\"Lato, sans-serif\"; */\n  margin: 0px;\n  box-sizing: border-box;\n  background-color: #0e3350;\n  height: auto;\n}\na {\n  color: #2467a3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
