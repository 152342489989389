// socket.js
import { io } from "socket.io-client";


// const socket = io("http://localhost:5000", {
  const socket = io("https://api.susan.fi", {
    transports: ["polling", "websocket"],
    reconnectionAttempts: 5,      // Limit number of reconnection attempts
    reconnectionDelay: 2000,      // Delay between reconnection attempts in milliseconds
    timeout: 10000,               // Increase the timeout for connection attempts
});
  

export default socket;
