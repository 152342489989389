// socket.js
import { io } from "socket.io-client";

// https://api.susan.fi
const socket = io("https://api.susan.fi", {
    transports: ["polling", "websocket"],
    reconnectionAttempts: 5,      // Limit number of reconnection attempts
    reconnectionDelay: 2000,      // Delay between reconnection attempts in milliseconds
    timeout: 10000,               // Increase the timeout for connection attempts
  });
  

export default socket;
