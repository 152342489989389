// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27%23ffffff%27%3E%3Cpath d=%27M7 10l5 5 5-5H7z%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.job-form-input{
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    appearance: none; /* Removes default arrow */
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 20px;
    background-color: white;
    cursor: pointer;
}
.checkbox-label {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    margin-top: 5px;
    cursor: pointer;
    color: white;
  }
  
  .checkbox-label input[type="checkbox"] {
    transform: scale(1.2);
    accent-color: #007bff;
    cursor: pointer;
  }
  .job-form-button{
    background-color: #25306b !important;
  }`, "",{"version":3,"sources":["webpack://./src/Chat/Messages/Education/index.css"],"names":[],"mappings":"AAAA;IAII,WAAW;IACX,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAElB,gBAAgB,EAAE,0BAA0B;IAC5C,wBAAwB;IACxB,qBAAqB;IACrB,yDAAqL;IACrL,4BAA4B;IAC5B,sCAAsC;IACtC,qBAAqB;IACrB,uBAAuB;IACvB,eAAe;AAhBnB;AAkBA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,eAAe;IACf,eAAe;IACf,eAAe;IACf,YAAY;EACd;;EAEA;IACE,qBAAqB;IACrB,qBAAqB;IACrB,eAAe;EACjB;EACA;IACE,oCAAoC;EACtC","sourcesContent":[".job-form-input{\n    width: 100%;\n}\n.job-form-input {\n    width: 100%;\n    padding: 10px;\n    font-size: 16px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    background-color: white;\n    appearance: none; /* Removes default arrow */\n    -webkit-appearance: none;\n    -moz-appearance: none;\n    background-image: url(\"data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ffffff'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E\");\n    background-repeat: no-repeat;\n    background-position: right 10px center;\n    background-size: 20px;\n    background-color: white;\n    cursor: pointer;\n}\n.checkbox-label {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    font-size: 14px;\n    margin-top: 5px;\n    cursor: pointer;\n    color: white;\n  }\n  \n  .checkbox-label input[type=\"checkbox\"] {\n    transform: scale(1.2);\n    accent-color: #007bff;\n    cursor: pointer;\n  }\n  .job-form-button{\n    background-color: #25306b !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
