import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import callStart from "../assets/Audio/call-start.mp3";
import callEnd from "../assets/Audio/call-end.mp3";
import "./chat.css";
import Header from "../Chat/Header";
import Messages from "../Chat/Messages";
import FormInput from "../Chat/FormInput";
import socket from "./socket";

// https://api.susan.fi
// http://localhost:5000/
// const socket = io("https://api.susan.fi", {
//   path: "/socket.io",
//   transports: ["polling", "websocket"],
// });

function Chat(props) {
  const [isDivVisible, setDivVisible] = useState(false);
  const [visibleAvatar, setVisibleAvatar] = useState(false);
  const [isCallActive, setIsCallActive] = useState(false);
  const [messages, setMessages] = useState([
    { type: "bot", text: "Hello! I am SUSAN, How can I help you today?" } // Initial welcome message
  ]);
  const [textValue, setTextValue] = useState("");
  const audioInstanceRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const callStartRef = useRef(null);
  const callEndRef = useRef(null);
  const timeoutRef = useRef(null);
  const messageEndRef = useRef(null);

  const googleCloudTTS = async (text) => {
    if (audioInstanceRef.current) {
      audioInstanceRef.current.pause();
      audioInstanceRef.current.currentTime = 0;
    }
  
    // Filter out unwanted symbols and HTML tags
    const cleanedText = text
      .replace(/<[^>]+>/g, "") // Remove HTML tags
      .replace(/\*\*|__|~~/g, "") // Remove markdown for bold, italic, strikethrough
      .replace(/https?:\/\/\S+/g, "") // Remove URLs (starting with http or https)
      .replace(/\[.*?\]\(.*?\)/g, ""); // Remove markdown links [text](url)
  
    const apiKey = "AIzaSyBs_GzMmIyN-MOFUnOVCiPGetlF0aCLgIM";
    const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`;
    const config = {
      input: { text: cleanedText },
      voice: { languageCode: "en-US", name: "en-US-Studio-O" },
      audioConfig: { audioEncoding: "MP3" },
    };
  
    try {
      const response = await axios.post(url, config);
      const audioContent = response.data.audioContent;
  
      if (audioContent) {
        const audio = new Audio(`data:audio/mp3;base64,${audioContent}`);
        audioInstanceRef.current = audio;
        audio.play();
        audio.onended = () => {
          console.log("Audio playback finished");
          audioInstanceRef.current = null;
        };
      }
    } catch (error) {
      console.error("Error calling Google Cloud TTS:", error);
    }
  };
  

  const startCall = async () => {
    setVisibleAvatar(true);
    googleCloudTTS("Hello! I am Susan, how can I help you today?");
    socket.emit("startTranscription");
    setIsCallActive(true);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "audio/webm;codecs=opus",
      });

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          socket.emit("audioChunk", event.data);
        }
      };

      mediaRecorderRef.current.start(300);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const handleTextChange = (event) => {
    setTextValue(event.target.value);
  };

  const handleTextSubmit = (event) => {
    // event.preventDefault();
    setIsCallActive(false);
    setMessages((prevMessages) => [
      ...prevMessages,
      { type: "user", text: textValue } // Add user message with textValue
    ]);
    socket.emit("userMessage", textValue);
    setTextValue("");

  };

  const endCall = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
      setDivVisible(false);
    }
  
    setVisibleAvatar(false);
    setIsCallActive(false);
  
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
      mediaRecorderRef.current = null;
    }
  
    if (callStartRef.current) {
      callStartRef.current.pause();
      callStartRef.current.currentTime = 0;
    }
  
    if (audioInstanceRef.current) {
      audioInstanceRef.current.pause();
      audioInstanceRef.current.currentTime = 0;
      audioInstanceRef.current = null;  // Reset the ref to ensure complete stop
    }
  
    if (callEndRef.current) {
      callEndRef.current.currentTime = 2;
      callEndRef.current.loop = false;
      callEndRef.current.play();
    }
  
    setTimeout(() => {
      if (callEndRef.current) {
        callEndRef.current.pause();
        callEndRef.current.currentTime = 0;
      }
    }, 2000);
  };
  

  const handleCallClick = async () => {
    if (isCallActive) {
      endCall();
      return;
    }

    setDivVisible(true);
    try {
      await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
      });

      if (callStartRef.current) {
        callStartRef.current.currentTime = 7;
        callStartRef.current.loop = true;
        callStartRef.current.play();
      }

      timeoutRef.current = setTimeout(() => {
        setDivVisible(false);
        if (callStartRef.current) {
          callStartRef.current.pause();
          callStartRef.current.currentTime = 0;
        }
        startCall();
      }, 5000);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {

    socket.on("botResponse", (response) => {
      setMessages((prevMessages) => {
        const updatedMessages = [
          ...prevMessages,
          ...response.map((msg) => ({
            type: msg.role === "assistant" ? "bot" : "user",
            text: msg.content,
          })),
        ];
        return updatedMessages;
      });

      const assistantMessage = response.find((msg) => msg.role === "assistant");
      console.log("assistantMessage: ", assistantMessage);
      // setIsCallActive(true)
      console.log("isCallActive Cheeck: ", response);
      if (assistantMessage && response[0].type === "call") {
        googleCloudTTS(assistantMessage.content);
      }
    });

    return () => {
      socket.off("botResponse");
    };
  }, []);

  return (
    <div className="chatbox bg-white shadow-lg">
      <div className="backdrop-blur-md bg-green bg-opacity-30 rounded-lg h-full p-4 flex flex-col">
        <Header
          visibleAvatar={visibleAvatar}
          isDivVisible={isDivVisible}
          Close={props.Close}
        />
        <div className="flex-grow overflow-y-auto scrollable-content">
          {messages.map((message, index) => (
            <Messages key={index} type={message?.type} text={message?.text} />
          ))}
          <div ref={messageEndRef} />
        </div>
        <FormInput
          textValue={textValue}
          handleTextChange={handleTextChange}
          handleTextSubmit={handleTextSubmit}
          handleCallClick={handleCallClick}
          isCallActive={isCallActive}
        />
      </div>
      <audio ref={callStartRef}>
        <source src={callStart} type="audio/mp3" />
      </audio>
      <audio ref={callEndRef}>
        <source src={callEnd} type="audio/mp3" />
      </audio>
    </div>
  );
}

export default Chat;
