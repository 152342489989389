import Chat from "./Chat/Chat.js";
import Open from "./Openchat/Open.js";
import { useState } from "react";

const App = () => {
  const [chatStart, setChatStart] = useState(true);
  
  const StartChat = () => {
    // setChatStart(!chatStart);
  };
  
  return (
    <>
      {chatStart ? <Chat Close={StartChat} /> : <Open Open={StartChat} />}
    </>
  );
}

export default App;
