import React, { useState } from "react";
import { HiOutlinePaperAirplane, HiPaperAirplane } from "react-icons/hi";
import { IoGlobeOutline } from "react-icons/io5";
import { FiPhoneCall, FiSquare } from "react-icons/fi";
import './index.css'

function Index({ 
  textValue,
  handleTextChange,
  handleTextSubmit,
  handleCallClick,
  isCallActive,
  isTextFieldBusy,
  sendDataToParent,
  messages,
  speakFunSpeaker,
  streamingContinue,
  isOnline
}) {
  const [selectedLanguage, setSelectedLanguage] = useState("Languages");
  const [isExpanded, setIsExpanded] = useState(false);
  const languages = [
    { code: "Languages", label: "Languages" },
    { code: "coming-soon", label: "coming-soon" },
  ];

// Function to check if the user is on a mobile device
const isMobileOrTablet = () => {
  return /iPhone|iPad|iPod|Android|Tablet|PlayBook|Silk/i.test(navigator.userAgent) 
    || (navigator.maxTouchPoints && navigator.maxTouchPoints > 1);
};


const handleKeyDown = (event) => {
  if (event.key === "Enter" && !isTextFieldBusy) {
    if (isMobileOrTablet()) {
      return;
    } 
    
    if (event.shiftKey) {
      event.preventDefault();
      const { selectionStart, selectionEnd, value } = event.target;
      const newValue = value.substring(0, selectionStart) + "\n" + value.substring(selectionEnd);
      event.target.value = newValue;
      event.target.setSelectionRange(selectionStart + 1, selectionStart + 1);
      return;
    }

    event.preventDefault();
    handleTextSubmit();
    event.target.blur();
  }
};

const startNewChat = (event) => {
  sendDataToParent('removed chat');
  localStorage.removeItem("chatMessages")
  localStorage.removeItem("latestQuestion")
  speakFunSpeaker("stop")
};

const handleFocus = () => setIsExpanded(true);
const handleBlur = (event) => {
  // Close only if the user clicks outside the entire container
  if (!event.currentTarget.contains(event.relatedTarget)) {
    setIsExpanded(false);
  }
};


  return (
<div className="mt-auto flex flex-col items-center relative">
  {
    navigator.onLine ? <div
    className={`w-full p-2 rounded-lg mt-2 transition-all duration-300 relative ${
      isExpanded ? "h-auto" : "h-[50px]"
    }`}
    style={{
      background: "#1b1b1b",
      border: "1px solid #363636",
      overflow: "hidden",
    }}
    onBlur={handleBlur}
    tabIndex="0"
  >
    {/* Textarea */}
    <textarea
      placeholder="Type a message..."
      value={textValue}
      onChange={handleTextChange}
      onKeyDown={handleKeyDown}
      className="w-full p-2 pr-10 bg-[#1b1b1b] rounded-md focus:outline-none border-none resize-none transition-all duration-300"
      rows={1}
      style={{
        background: "#1b1b1b",
        color: "white",
        height: "auto",
        maxHeight: "10rem",
        overflowY: "auto",
        paddingRight: "3rem", // Space for send button
      }}
      onFocus={handleFocus}
    />

    {/* Floating Send Button */}
    {
      isExpanded ? 
    <div
      className={`absolute right-3 flex items-center justify-center w-8 h-8 rounded-full hover:bg-[#dfd9d9] cursor-pointer transition-all duration-200 ${
        isTextFieldBusy || streamingContinue ? "cursor-not-allowed opacity-50" : ""
      }`}
      style={{
        backgroundColor: "#15313b",
        pointerEvents: isTextFieldBusy || streamingContinue ? "none" : "auto",
        right: '10px',
        bottom: '8px'
      }}
      onClick={!isTextFieldBusy || !streamingContinue ? handleTextSubmit : undefined}
    >
      {
        isTextFieldBusy || streamingContinue ? <FiSquare style={{
          color: "#e5e7eb",
          backgroundColor: "#e5e7eb",
          animation: "glowZoom 1.2s infinite ease-in-out",
        }} 
        size={10}/> : <HiOutlinePaperAirplane
        className="text-lg transform rotate-90"
        style={{ color: streamingContinue ? "#666" : "#c7b8b8" }}
      />
      }

    </div>
      : 
      <div className={`absolute right-3 flex items-center justify-center w-8 h-8 rounded-full hover:bg-[#dfd9d9] cursor-pointer transition-all duration-200 ${
        isCallActive ? "blinking-icon" : ""}`}
      onClick={handleCallClick}
      style={{
        backgroundColor: isCallActive ? "#d20612" : "#15313b",
        right: '10px',
        bottom: '8px'
      }}
    >
      <FiPhoneCall className="text-lg" style={{ color: "#c7b8b8" }} />
    </div>
    }

    {/* Expanded Content (Hidden Until Clicked) */}
    {isExpanded && (
      <div className="flex space-x-2 mt-2 justify-between py-10 transition-all duration-300">
        <div className="flex items-center justify-center">
          {/* Call Button */}
          <div
            className={`flex items-center justify-center w-8 h-8 rounded-full hover:bg-[#dfd9d9] cursor-pointer ${
              isCallActive ? "blinking-icon" : ""
            }`}
            onClick={handleCallClick}
            style={{ backgroundColor: isCallActive ? "#d20612" : "#15313b" }}
          >
            <FiPhoneCall className="text-lg" style={{ color: "#c7b8b8" }} />
          </div>

          {/* Language Selector */}
          <div className="select-container">
            <IoGlobeOutline className="select-icon" />
            <select
              value={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.target.value)}
              className="select-menu"
            >
              {languages.map((lang,key) => (
                <option key={key} value={lang.code}>
                  {lang.label}
                </option>
              ))}
            </select>
          </div>

          {/* New Chat Button */}
          {messages.length > 0 && (
            <div
              className={`flex items-center new-chat-btn cursor-pointer ${
                isTextFieldBusy ? "cursor-not-allowed" : ""
              }`}
              onClick={() => startNewChat()}
            >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.856 17.121a.979.979 0 0 1-.327-.06.839.839 0 0 1-.283-.177.739.739 0 0 1-.187-.255.724.724 0 0 1-.07-.303l-.02-1.609a4.663 4.663 0 0 1-1.446-.455 4.252 4.252 0 0 1-.637-.401c-.199-.146-.385-.31-.553-.492a4.442 4.442 0 0 1-.45-.577 4.303 4.303 0 0 1-.327-.637 3.823 3.823 0 0 1-.206-.686 3.729 3.729 0 0 1-.064-.704V6.478c0-.261.025-.516.077-.771a4.43 4.43 0 0 1 .244-.747 4.062 4.062 0 0 1 .932-1.28c.2-.183.418-.347.65-.493.23-.145.482-.267.739-.364a4.21 4.21 0 0 1 .81-.225c.27-.054.553-.078.835-.078H8.55c.103 0 .2.018.29.054a.7.7 0 0 1 .411.376.667.667 0 0 1-.161.766.736.736 0 0 1-.25.151.764.764 0 0 1-.29.055H5.573c-.186 0-.366.012-.54.049-.18.03-.353.079-.52.145-.167.061-.328.14-.482.237-.148.091-.29.2-.418.316a2.897 2.897 0 0 0-.347.388c-.097.14-.187.286-.257.444a2.473 2.473 0 0 0-.206.977v4.287c0 .17.013.333.051.503a2.549 2.549 0 0 0 .772 1.33 2.721 2.721 0 0 0 .913.559c.167.066.347.115.527.152.18.03.36.048.546.048a.904.904 0 0 1 .61.23.848.848 0 0 1 .194.262.84.84 0 0 1 .07.303l.007.99 1.915-1.293a2.877 2.877 0 0 1 1.64-.492h2.372c.186 0 .366-.018.54-.048.18-.03.353-.08.52-.146.168-.067.329-.146.483-.237.148-.091.29-.2.418-.316.128-.121.244-.249.347-.388a2.8 2.8 0 0 0 .257-.444 2.47 2.47 0 0 0 .206-.977V8.585a.646.646 0 0 1 .225-.492.679.679 0 0 1 .244-.152.814.814 0 0 1 .585 0c.09.03.174.085.244.152a.657.657 0 0 1 .225.492V10.8c0 .261-.032.516-.083.771a4.192 4.192 0 0 1-.245.74c-.109.244-.244.468-.398.687a3.735 3.735 0 0 1-.534.6c-.2.183-.418.347-.65.493a4.134 4.134 0 0 1-.738.364 4.7 4.7 0 0 1-.81.225c-.27.054-.553.079-.836.079h-1.877c-.604 0-1.144.164-1.633.491l-2.54 1.713a.913.913 0 0 1-.514.157z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M15.866 4.125h-4.174c-.41 0-.741.313-.741.7 0 .387.332.7.741.7h4.174c.41 0 .742-.313.742-.7 0-.387-.332-.7-.742-.7z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M14.537 2.932c0-.396-.34-.717-.759-.717s-.758.32-.758.717v3.786c0 .396.34.717.758.717.42 0 .76-.321.76-.717V2.932z"
                    fill="currentColor"
                  ></path>
                </svg>
              <span>New chat</span>
            </div>
          )}
        </div>
      </div>
    )}
  </div>  : <div
    className={`w-full p-2 rounded-lg mt-2 transition-all duration-300 relative ${
      isExpanded ? "h-auto" : "h-[50px]"
    }`}
    style={{
      background: "#1b1b1b",
      border: "1px solid #363636",
      overflow: "hidden",
    }}
    onBlur={handleBlur}
    tabIndex="0"
  >
    <textarea
      placeholder="Internet not available..."
      className="w-full p-2 pr-10 bg-[#1b1b1b] rounded-md focus:outline-none border-none resize-none transition-all duration-300"
      rows={1}
      style={{
        background: "#1b1b1b",
        color: "white",
        height: "auto",
        maxHeight: "10rem",
        overflowY: "auto",
        paddingRight: "3rem", // Space for send button
      }}
    />
  </div>

  }

</div>


  );
}

export default Index;
