import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { GoogleOAuthProvider } from '@react-oauth/google';

import callStart from "../assets/Audio/call-start.mp3";
import callEnd from "../assets/Audio/call-end.mp3";
import send from "../assets/Audio/send.mp3";
import recieve from "../assets/Audio/recieve.mp3";
import "./chat.css";
import Header from "../Chat/Header";
import Messages from "../Chat/Messages";
import FormInput from "../Chat/FormInput";
import socket from "./socket";
import { useSelector } from "react-redux";

function Chat(props) {
  const { isOnline } = props;
  const [childData, setChildData] = useState("");
  const [activeButtonState, setActiveButtonState] = useState({});
  const [textarea, setTextArea] = useState([]);
  const [streamingContinue, setStreamingContinue] = useState(false);
  const [jobDetails, setJobDetails] = useState({ title: "", location: "" });

  // const [questions, setQuestions] = useState([]);
  const [questions, setQuestions] = useState(() => {
    return JSON.parse(localStorage.getItem("latestQuestion")) || [];
  });

  const [botBusy, setBotBusy] = useState(false);
  const [textFieldBusy, setTextFieldBusy] = useState(false);
  const [loading, setLoading] = useState(false);
  const audioInstancesRef = useRef([]);
  const [isDivVisible, setDivVisible] = useState(false);
  const [visibleAvatar, setVisibleAvatar] = useState(false);
  const [isCallActive, setIsCallActive] = useState(false);
  const [messages, setMessages] = useState([]);
  const [userStream, setUserStream] = useState([]);
  const [streamingState, setStreamingState] = useState(false);
  const [checkSpeaker, setCheckSpeaker] = useState({});
  const [textValue, setTextValue] = useState("");

  const timeoutRefTTs = useRef(null);
  const timeoutRefCallEnd = useRef(null);
  // const textareaRef = useRef(null);
  const audioInstanceRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const callStartRef = useRef(null);
  const callEndRef = useRef(null);
  const timeoutRef = useRef(null);
  const messageEndRef = useRef(null);
  const isCallActiveRef = useRef(false);

  const educationMessages = useSelector((state) => state.auth.educationMessages);
  const BotResponseEducation = useSelector((state) => state.auth.botResponseEducation);

  const handleChildData = (data) => {
    setMessages([]);
    setQuestions([]);
    setChildData(data); // Store child data in state
  };

  const getReadingTime = (text, speakingRate = 1.0) => {
    const wordsPerMinute = 160 * speakingRate; // Adjust WPM based on speakingRate
    const wordsPerSecond = wordsPerMinute / 60;
    const wordCount = text.split(/\s+/).length; // Count words
    const estimatedTime = wordCount / wordsPerSecond;
    return Math.ceil(estimatedTime); // Round up to the nearest second
  };

  const startCall = async () => {
    let chatHistory = JSON.parse(localStorage.getItem("chatMessages")) || [];
    setVisibleAvatar(true);
    setIsCallActive(true);
    isCallActiveRef.current = true;

    googleCloudTTS("HELLO, Let's chat about life in Finland...");
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        role: "assistant",
        type: "call",
        text: `## HELLO, Let's chat about life in Finland...`,
        timestamp: new Date().toISOString(),
      },
    ]);
    socket.emit("detectHumanVoice");
    socket.emit("startTranscription", chatHistory[0]?.sessionId);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // Detect Safari
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      );

      mediaRecorderRef.current = new MediaRecorder(stream, {
        // mimeType: "audio/webm;codecs=opus",
        mimeType: isSafari ? "audio/mp4" : "audio/webm;codecs=opus",
      });

      mediaRecorderRef.current.ondataavailable = (event) => {
        // if (event.data.size > 0) {
        socket.emit("audioChunk", event.data);
        // }
      };

      mediaRecorderRef.current.start(30);

      const voiceDetectionRecorder = new MediaRecorder(stream, {
        // mimeType: "audio/webm;codecs=opus",
        mimeType: isSafari ? "audio/mp4" : "audio/webm;codecs=opus",
      });

      voiceDetectionRecorder.ondataavailable = (event) => {
        socket.emit("humanVoiceChunks", event.data);
      };
      voiceDetectionRecorder.start(100);

      // Cleanup logic
      mediaRecorderRef.current.onstop = () => {
        voiceDetectionRecorder.stop();
      };
    } catch (error) {
      console.error("Error accessing microphone:", error);
      setBotBusy(false);
    }
  };

  const googleCloudTTS = async (text, type) => {
    if (!isCallActiveRef.current) {
      return;
    }

    if (text === "stop") {
      clearTimeout(timeoutRefTTs.current);
      clearTimeout(timeoutRefCallEnd.current);

      audioInstancesRef.current.forEach((audio) => {
        if (audio) {
          audio.pause();
          audio.currentTime = 0;
          audio.src = "";
        }
      });
      audioInstancesRef.current = [];

      // Clear any existing timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }

      setActiveButtonState(null);
      return;
    }

    // Stop and clear any currently playing audio
    if (audioInstancesRef.current.length > 0) {
      audioInstancesRef.current.forEach((audio) => {
        if (audio) {
          audio.pause();
          audio.currentTime = 0;
          audio.src = "";
        }
      });
      audioInstancesRef.current = [];
    }

    const cleanedText = text
      .replace(/(\d+)\.(\d+)/g, "$1 point $2") // Fix decimal numbers
      .replace(/\be\.g\.,?\s*/gi, "for example ") // Replace "e.g.," with "for example"
      .replace(/\bi\.e\.,?\s*/gi, "that is ") // Replace "i.e.," with "that is"
      .replace(/\s*\.\s*/g, ". ") // Ensure single space after periods
      .replace(/(?<!\d)\.(?!\d)/g, "") // Remove standalone dots not part of a number
      .replace(/,\s*([,.])/g, "$1") // Remove unnecessary commas before dots
      .replace(/\s*([,.])\s*/g, "$1 ") // Ensure proper spacing around commas and periods
      .replace(/<[^>]+>/g, "") // Removes HTML tags
      .replace(/\\||/g, "") // Removes backslashes and pipe symbols
      .replace(/https?:\/\/\S+/g, "") // Removes URLs
      .replace(/\[(.*?)\]\(.*?\)/g, "$1") // Removes markdown links
      .replace(/#{1,}/g, "") // Removes markdown headers
      .replace(/\*/g, "") // Removes asterisks
      .replace(
        /([\u2700-\u27BF]|[\uE000-\uF8FF]|\u24C2|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|\uD83E[\uDD00-\uDDFF])/g,
        ""
      );

    const sentences = cleanedText
      .split(/(?<=\D)(?=\d+\s*[,.])/g)
      .map((s) => s.trim())
      .filter(Boolean);

    const apiKey = "AIzaSyBs_GzMmIyN-MOFUnOVCiPGetlF0aCLgIM";
    const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`;

    const playAudio = async (audioContent) => {
      return new Promise((resolve) => {
        const audio = new Audio(`data:audio/mp3;base64,${audioContent}`);

        // Stop any previous audio
        if (audioInstanceRef.current) {
          audioInstanceRef.current.pause();
          audioInstanceRef.current.currentTime = 0;
          audioInstanceRef.current.src = "";
        }

        audioInstanceRef.current = audio;
        audioInstancesRef.current.push(audio);

        audio.onended = () => {
          resolve();
        };

        audio.play();
      });
    };

    for (const sentence of sentences) {
      const config = {
        input: { text: sentence },
        voice: { languageCode: "en-US", name: "en-US-Journey-F" },
        audioConfig: { audioEncoding: "MP3" },
      };

      try {
        const response = await axios.post(url, config);
        const audioContent = response.data.audioContent;

        if (audioContent) {
          const speakingRate = 1.0;
          const estimatedTime = getReadingTime(cleanedText, speakingRate);

          if (
            text !==
            `Are you still there? I haven't heard from you. I'll end the call soon unless you respond.`
          ) {
            if (timeoutRefTTs.current || timeoutRefCallEnd.current) {
              clearTimeout(timeoutRefTTs.current);
              clearTimeout(timeoutRefCallEnd.current);
            }

            timeoutRefTTs.current = setTimeout(() => {
              googleCloudTTS(
                "Are you still there? I haven't heard from you. I'll end the call soon unless you respond."
              );
              setMessages((prevMessages) => [
                ...prevMessages,
                {
                  role: "assistant",
                  type: "call",
                  text: `## Are you still there? I haven't heard from you. I'll end the call soon unless you respond.`,
                  timestamp: new Date().toISOString(),
                },
              ]);

              if (timeoutRefTTs.current) {
                clearTimeout(timeoutRefTTs.current);
              }
            }, estimatedTime * 1000 + 20000);

            timeoutRefCallEnd.current = setTimeout(() => {
              endCall();
              if (timeoutRefCallEnd.current) {
                // clearTimeout(timeoutRefTTs.current);
                clearTimeout(timeoutRefCallEnd.current);
              }
            }, estimatedTime * 1000 + 30000);
          }
          await playAudio(audioContent);
        }
      } catch (error) {
        console.error("Error calling Google Cloud TTS:", error);
      }
    }
  };

  // const elevenLabsTTS = async (text) => {
  //   // Stop and clear any currently playing audio
  //   if (audioInstancesRef.current.length > 0) {
  //     audioInstancesRef.current.forEach((audio) => {
  //       if (audio) {
  //         audio.pause();
  //         audio.currentTime = 0;
  //         audio.src = "";
  //       }
  //     });
  //     audioInstancesRef.current = [];
  //   }

  //   //text = Moving to Finland Relocating to ---- Finland ... involves several steps.;

  //   const cleanedText = text
  //     .replace(/<[^>]+>/g, "")
  //     .replace(/\\||/g, "")
  //     .replace(/https?:\/\/\S+/g, "")
  //     .replace(/\[.?\]\(.?\)/g, "")
  //     .replace(/#{1,}/g, "");

  //   // Prepare the Eleven Labs TTS API request payload
  //   const apiUrl =
  //     "https://api.elevenlabs.io/v1/text-to-speech/aMSt68OGf4xUZAnLpTU8?optimize_streaming_latency=0&output_format=mp3_22050_32";
  //   const apiKey = "sk_edd92d292dc10d87198ecefcf04f37a35162f52b92cc3350"; // Add your Eleven Labs API key here
  //   const requestBody = {
  //     text: cleanedText,
  //     voice_settings: {
  //       stability: 0.5,
  //       similarity_boost: 0.75,
  //       style: 1,
  //     },
  //     model_id: "eleven_flash_v2_5",
  //   };

  //   try {
  //     const response = await axios.post(apiUrl, requestBody, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         "xi-api-key": apiKey,
  //       },
  //       responseType: "arraybuffer", // Ensure the response is interpreted as binary data
  //     });

  //     // Convert the ArrayBuffer into a Blob
  //     const audioBlob = new Blob([response.data], { type: "audio/mp3" });

  //     // Create a URL for the audio Blob
  //     const audioUrl = URL.createObjectURL(audioBlob);

  //     if (audioUrl) {
  //       const audio = new Audio(audioUrl);
  //       // console.log('audio: ', audio);
  //       // Stop any previous audio
  //       if (audioInstanceRef.current) {
  //         audioInstanceRef.current.pause();
  //         audioInstanceRef.current.currentTime = 0;
  //         audioInstanceRef.current.src = "";
  //       }

  //       audioInstanceRef.current = audio;
  //       audioInstancesRef.current.push(audio);

  //       audio.play();

  //       // Start monitoring for user speech
  //       monitorUserSpeech(audio);

  //       audio.onended = () => {
  //         console.log("Audio playback finished");
  //         const index = audioInstancesRef.current.indexOf(audio);
  //         if (index > -1) audioInstancesRef.current.splice(index, 1);
  //         audioInstanceRef.current = null;
  //       };
  //     }
  //   } catch (error) {
  //     console.error("Error calling Eleven Labs TTS:", error);
  //   }
  // };

  // const monitorUserSpeech = (audio) => {
  //   navigator.mediaDevices
  //     .getUserMedia({
  //       audio: {
  //         echoCancellation: true,
  //         noiseSuppression: true,
  //         autoGainControl: false,
  //       },
  //     })
  //     .then((stream) => {
  //       const audioContext = new (window.AudioContext ||
  //         window.webkitAudioContext)();
  //       const microphone = audioContext.createMediaStreamSource(stream);
  //       const analyser = audioContext.createAnalyser();
  //       analyser.fftSize = 2048; // Higher FFT size for better frequency resolution
  //       const bufferLength = analyser.frequencyBinCount;
  //       const dataArray = new Uint8Array(bufferLength);

  //       // Connect microphone to analyser
  //       microphone.connect(analyser);

  //       // Initialize thresholds and variables
  //       const volumeThreshold = 15; // Base volume threshold
  //       const speechFrequencyRange = { low: 300, high: 3000 }; // Human speech frequency range in Hz
  //       const speechDurationThreshold = 500; // 500ms of speech detection before reacting
  //       let lastSpeechTime = Date.now();
  //       let isSpeechDetected = false;

  //       const detectSpeech = () => {
  //         analyser.getByteFrequencyData(dataArray);

  //         // Analyze specific frequency ranges for speech detection
  //         const sampleRate = audioContext.sampleRate; // Get the sample rate of the audio context
  //         const startFrequencyIndex = Math.floor(
  //           (speechFrequencyRange.low / sampleRate) * analyser.fftSize
  //         );
  //         const endFrequencyIndex = Math.ceil(
  //           (speechFrequencyRange.high / sampleRate) * analyser.fftSize
  //         );

  //         let totalSpeechFrequencyVolume = 0;
  //         let totalOtherFrequencyVolume = 0;

  //         for (let i = 0; i < bufferLength; i++) {
  //           if (i >= startFrequencyIndex && i <= endFrequencyIndex) {
  //             totalSpeechFrequencyVolume += dataArray[i]; // Sum speech frequencies
  //           } else {
  //             totalOtherFrequencyVolume += dataArray[i]; // Sum other frequencies
  //           }
  //         }

  //         const averageSpeechFrequencyVolume =
  //           totalSpeechFrequencyVolume /
  //           (endFrequencyIndex - startFrequencyIndex + 1);
  //         const averageOtherFrequencyVolume =
  //           totalOtherFrequencyVolume /
  //           (bufferLength - (endFrequencyIndex - startFrequencyIndex + 1));

  //         // console.log("Speech Frequency Volume:", averageSpeechFrequencyVolume);
  //         // console.log("Other Frequency Volume:", averageOtherFrequencyVolume);

  //         if (
  //           averageSpeechFrequencyVolume > volumeThreshold &&
  //           averageSpeechFrequencyVolume > averageOtherFrequencyVolume * 1.2
  //         ) {
  //           // Speech detected if it's louder than both the threshold and other frequencies
  //           if (!isSpeechDetected) {
  //             isSpeechDetected = true;
  //             console.log("Speech detected!");
  //           }
  //           lastSpeechTime = Date.now();
  //         } else {
  //           // If no speech is detected
  //           if (isSpeechDetected) {
  //             const timeSinceLastSpeech = Date.now() - lastSpeechTime;
  //             if (timeSinceLastSpeech > speechDurationThreshold) {
  //               console.log("Speech detected, stopping bot audio...");
  //               audio.pause();
  //               audio.currentTime = 0;
  //               audio.volume = 0.1;

  //               // Clean up resources
  //               stream.getTracks().forEach((track) => track.stop());
  //               analyser.disconnect();
  //               microphone.disconnect();
  //               clearInterval(intervalId);
  //             }
  //           }
  //         }
  //       };

  //       // Run speech detection every 100ms
  //       const intervalId = setInterval(detectSpeech, 100);

  //       // Cleanup when audio ends or pauses
  //       const cleanup = () => {
  //         clearInterval(intervalId);
  //         stream.getTracks().forEach((track) => track.stop());
  //         analyser.disconnect();
  //         microphone.disconnect();
  //       };

  //       audio.onended = cleanup;
  //       audio.onpause = cleanup;
  //     })
  //     .catch((error) => {
  //       console.error("Error accessing microphone:", error);
  //     });
  // };

  const speakFunSpeaker = async (text, type, messageId) => {
    isCallActiveRef.current = true;
    // Stop and clear any currently playing audio
    if (audioInstancesRef.current.length > 0) {
      audioInstancesRef.current.forEach((audio) => {
        if (audio) {
          audio.pause();
          audio.currentTime = 0;
          audio.src = "";
        }
      });
      audioInstancesRef.current = [];
    }
    if (text === "stop") {
      setCheckSpeaker((prevState) => ({
        ...prevState,
        [messageId]: false,
      }));
      clearTimeout(timeoutRefTTs.current);
      clearTimeout(timeoutRefCallEnd.current);

      audioInstancesRef.current.forEach((audio) => {
        if (audio) {
          audio.pause();
          audio.currentTime = 0;
          audio.src = "";
        }
      });
      audioInstancesRef.current = [];

      // Clear any existing timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }

      setActiveButtonState(null);
      return;
    }
    setCheckSpeaker((prevState) => ({
      ...prevState,
      [messageId]: true,
    }));
    const cleanedText = text
      .replace(/(\d+)\.(\s*[A-Za-z])/g, "$1. $2")
      .replace(/(\d+)\.(?!\d)/g, "$1, ")
      .replace(/\be\.g\.,?\s*/gi, "for example ")
      .replace(/\bi\.e\.,?\s*/gi, "that is ")
      .replace(/\s*\.\s*/g, ". ")
      .replace(/(?<!\d)\.(?!\d)/g, ".")
      .replace(/,\s*([,.])/g, "$1")
      .replace(/\s*([,.])\s*/g, "$1 ")
      .replace(/<[^>]+>/g, "")
      .replace(/\\||/g, "")
      .replace(/https?:\/\/\S+/g, "")
      .replace(/\[(.*?)\]\(.*?\)/g, "$1")
      .replace(/#{1,}/g, "")
      .replace(/\*/g, "")
      .replace(/\s{2,}/g, " ")
      .replace(
        /[\u2700-\u27BF\uE000-\uF8FF\u24C2\uD83C\uDC00-\uDFFF\uD83D\uDC00-\uDFFF\uD83E\uDD00-\uDDFF]/g,
        ""
      )
      .replace(/\s*-\s*/g, " ") // Remove standalone dashes between words
      .replace(/(Company:)/g, ". $1") // Add a pause before "Company"
      .replace(/(Location:)/g, ". $1") // Add a pause before "Location"
      .replace(/Apply/g, "Apply.") // Ensure "Apply" ends with a period for pause
      .replace(/\n+/g, ". ");

    const splitSentences = (text, maxLength = 150) => {
      // Preserve list structures and sentence flow properly
      const rawSentences = text
        .replace(/\n+/g, "\n") // Normalize newlines
        .replace(/-\s+/g, "- ") // Preserve list bullet points
        .match(/[^.!?\n]+[.!?\n]?\s*/g) || [text]; // Match full sentences and lists

      const chunks = [];
      let tempChunk = "";

      rawSentences.forEach((sentence) => {
        sentence = sentence.trim();
        if (!sentence) return; // Skip empty lines

        if (sentence.length > maxLength) {
          // If a single sentence is too long, break it at spaces (not mid-word)
          const subSentences = sentence.match(
            new RegExp(`.{1,${maxLength}}(\\s|$)`, "g")
          ) || [sentence];
          subSentences.forEach((subSentence) => {
            if (tempChunk.length > 0) {
              chunks.push(tempChunk.trim());
              tempChunk = "";
            }
            chunks.push(subSentence.trim());
          });
        } else if ((tempChunk + " " + sentence).trim().length > maxLength) {
          // If adding the sentence exceeds maxLength, push current chunk
          if (tempChunk.trim().length > 0) {
            chunks.push(tempChunk.trim());
          }
          tempChunk = sentence;
        } else {
          // Add sentence to the current chunk
          tempChunk += (tempChunk ? " " : "") + sentence;
        }
      });

      if (tempChunk.trim().length > 0) {
        chunks.push(tempChunk.trim());
      }

      return chunks;
    };
    const sentences = splitSentences(cleanedText);

    const apiKey = "AIzaSyBs_GzMmIyN-MOFUnOVCiPGetlF0aCLgIM";
    const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`;

    const playAudio = async (audioContent) => {
      return new Promise((resolve) => {
        const audio = new Audio(`data:audio/mp3;base64,${audioContent}`);

        // Stop any previous audio
        if (audioInstanceRef.current) {
          audioInstanceRef.current.pause();
          audioInstanceRef.current.currentTime = 0;
          audioInstanceRef.current.src = "";
        }

        audioInstanceRef.current = audio;
        audioInstancesRef.current.push(audio);

        audio.onended = () => {
          resolve();
        };

        // Reset state after speaking
        setCheckSpeaker((prevState) => ({
          ...prevState,
          [messageId]: false, // Set as inactive after completion
        }));
        audio.play();
      });
    };

    for (const sentence of sentences) {
      const config = {
        input: { text: sentence },
        voice: { languageCode: "en-US", name: "en-US-Journey-F" },
        audioConfig: { audioEncoding: "MP3" },
      };

      try {
        const response = await axios.post(url, config);
        const audioContent = response.data.audioContent;

        if (audioContent) {
          await playAudio(audioContent);
        }
      } catch (error) {
        console.error("Error Google Cloud TTS:", error);
      }
    }
    setActiveButtonState(null);
  };

  const handleTextChange = (event) => {
    const textarea = event.target;
    setTextArea(textarea);
    setTextValue(event.target.value);
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;

    const maxHeight = 7 * 24; // Approx 4 lines (24px per line)
    if (textarea.scrollHeight > maxHeight) {
      textarea.style.height = `${maxHeight}px`; // Limit max height
      textarea.style.overflowY = "auto"; // Enable scrolling when exceeding 4 lines
    } else {
      textarea.style.overflowY = "hidden"; // Hide scrollbar if within limit
    }
  };

  const handleQuestionClick = (question) => {
    const audio = new Audio(send);
    audio.play().catch((error) => console.error("Error playing sound:", error));
    let type = visibleAvatar ? "call" : "text";
    setLoading(true);
    // Set the clicked question as input text and directly send it
    // setMessages((prevMessages) => [
    //   ...prevMessages,
    //   { role:'user', type: type, text: question, timestamp:new Date().toISOString() },
    // ]);
    // setUserStream();
    if (type === "call") {
      setUserStream((prevMessages) => [
        {
          text: question,
        },
      ]);
    } else if (type === "text") {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: "user",
          type: type,
          text: question,
          timestamp: new Date().toISOString(),
        },
      ]);
      let chatHistory = JSON.parse(localStorage.getItem("chatMessages")) || [];

      // Get current sessionId
      const sessionId = socket.id;

      // Check if a session exists, else create one
      if (chatHistory.length === 0) {
        chatHistory.push({
          _id: sessionId, // Unique session ID
          sessionId,
          createdAt: new Date().toISOString(),
          messages: [],
        });
      }

      // Create the new user message
      const newMessage = {
        role: "user",
        text: question,
        timestamp: new Date().toISOString(),
      };

      // Push the new message into the session's messages array
      chatHistory[0].messages.push(newMessage);

      // Update React state
      // setMessages((prevMessages) => [...prevMessages, newMessage]);

      // Save updated chat history in localStorage
      localStorage.setItem("chatMessages", JSON.stringify(chatHistory));
      // setMessages((prevMessages) => [
      //   ...prevMessages,
      //   {
      //     type: "text",
      //     role: "user",
      //     text: question,
      //     timestamp: new Date().toISOString(),
      //   },
      // ]);
      // console.log("Text Value:", textValue);
    }
    let chatHistory = JSON.parse(localStorage.getItem("chatMessages")) || [];
    socket.emit("userMessage", question, type, chatHistory[0].sessionId); // Emit the clicked question
    setTextFieldBusy(true); // Set the text field as busy
    // setIsCallActive(false); // Ensure the call is inactive
    setTextValue(""); // Clear the input field
  };

  const handleTextSubmit = (event) => {
    if (!textValue.trim()) return;
    const audio = new Audio(send);
    audio.play().catch((error) => console.error("Error playing sound:", error));
    setLoading(true);
    setTextFieldBusy(true);

    isCallActive ? setIsCallActive(true) : setIsCallActive(false);

    let chatHistory = JSON.parse(localStorage.getItem("chatMessages")) || [];

    // Get current sessionId
    const sessionId = socket.id;
    const uniqueId = uuidv4();
    // Check if a session exists, else create one
    if (chatHistory.length === 0) {
      chatHistory.push({
        _id: sessionId, // Unique session ID
        sessionId: uniqueId,
        createdAt: new Date().toISOString(),
        messages: [],
      });
    }

    // Create the new user message
    const newMessage = {
      role: "user",
      text: textValue,
      timestamp: new Date().toISOString(),
    };

    // Push the new message into the session's messages array
    chatHistory[0].messages.push(newMessage);

    // Update React state
    // setMessages((prevMessages) => [...prevMessages, newMessage]);

    // Save updated chat history in localStorage
    localStorage.setItem("chatMessages", JSON.stringify(chatHistory));
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        type: "text",
        role: "user",
        text: textValue,
        timestamp: new Date().toISOString(),
      },
    ]);
    // console.log("Text Value:", textValue);

    socket.emit(
      "userMessage",
      textValue,
      isCallActive ? "textCall" : "text",
      chatHistory[0].sessionId
    );
    setTextValue("");
    textarea.style.height = "auto";
    // event.target.blur();
  };
  const handleJobSearch = (title, location) => {
    // setJobDetails({ title, location });
    let chatHistory = JSON.parse(localStorage.getItem("chatMessages")) || [];
    const job = `I need job of ${title} in ${location}`
    
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        role: "user",
        type: 'type',
        text: job,
        timestamp: new Date().toISOString(),
      },
    ]);

    setLoading(true);
    // ####################
    const sessionId = socket.id;
    const uniqueId = uuidv4();
    // Check if a session exists, else create one
    if (chatHistory.length === 0) {
      chatHistory.push({
        _id: sessionId, // Unique session ID
        sessionId: uniqueId,
        createdAt: new Date().toISOString(),
        messages: [],
      });
    }

    // Create the new user message
    const newMessage = {
      role: "user",
      text: job,
      timestamp: new Date().toISOString(),
    };

    // Push the new message into the session's messages array
    chatHistory[0].messages.push(newMessage);
    
    
    socket.emit("userMessage", job, 'text', chatHistory[0].sessionId); // Emit the clicked question

    localStorage.setItem("chatMessages", JSON.stringify(chatHistory));
    // ####################
  };

  const endCall = () => {
    socket.emit("stopTranscription"); // Ensure server cleans up streams
    clearTimeout(timeoutRefTTs.current);
    clearTimeout(timeoutRefCallEnd.current);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
      setDivVisible(false);
    }

    setVisibleAvatar(false);
    setIsCallActive(false);
    isCallActiveRef.current = false;

    // Stop media recorder stream
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
      mediaRecorderRef.current = null;
    }

    // Pause and reset all audio instances
    audioInstancesRef.current.forEach((audio) => {
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    });
    audioInstancesRef.current.length = 0;

    // Handle call start audio
    if (callStartRef.current) {
      callStartRef.current.pause();
      callStartRef.current.currentTime = 0;
    }

    // Handle call end audio with safe play
    if (callEndRef.current) {
      try {
        callEndRef.current.currentTime = 2; // Ensure correct timing
        callEndRef.current.loop = false;

        // Safely play the audio
        const playPromise = callEndRef.current.play();
        if (playPromise !== undefined) {
          playPromise.catch((error) => {
            console.warn("Play was interrupted:", error);
          });
        }
      } catch (error) {
        console.error("Error playing call end audio:", error);
      }
    }

    // Pause the call end audio after a timeout
    setTimeout(() => {
      if (callEndRef.current) {
        callEndRef.current.pause();
        callEndRef.current.currentTime = 0;
      }
    }, 2000);
  };

  const handleCallClick = async () => {
    if (isCallActive) {
      endCall();
      return;
    }

    isCallActiveRef.current = true;
    setDivVisible(true);
    try {
      await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
      });

      if (callStartRef.current) {
        callStartRef.current.currentTime = 7;
        callStartRef.current.loop = true;
        callStartRef.current.play();
      }

      timeoutRef.current = setTimeout(() => {
        setDivVisible(false);
        if (callStartRef.current) {
          callStartRef.current.pause();
          callStartRef.current.currentTime = 0;
        }
        startCall();
      }, 3000);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    socket.on("botResponse", (response) => {
      setStreamingState(false);

      const sessionId = socket.id;
      let chatHistory = JSON.parse(localStorage.getItem("chatMessages")) || [];

      if (chatHistory.length > 0) {
        const uniqueId = uuidv4();
        if (!chatHistory[0].sessionId) {
          console.log(uniqueId);
          console.log("working...1 ");
          chatHistory[0].sessionId = uniqueId;
        }
      } else {
        const uniqueId = uuidv4();
        // Create a new chat session if no previous history exists
        chatHistory.push({
          _id: sessionId, // Unique session ID
          sessionId: uniqueId,
          createdAt: new Date().toISOString(),
          messages: [],
        });
      }
      let newMessages = [];

      if (response[0].userPrompt) {
        setMessages((prevMessages) => [
          ...prevMessages,
          ...response.map((msg) => ({
            role: "user",
            type: msg.type,
            text: msg.userPrompt,
            timestamp: msg.timestamp,
          })),
        ]);

        if (
          response[0]?.userPrompt &&
          (response[0].role === "user" || response[0].role === "assistant") &&
          response[0].content !== "error"
        ) {
          chatHistory[0].messages.push({
            role: "user",
            text: response[0].userPrompt,
            timestamp: new Date().toISOString(),
          });
        }

        localStorage.setItem("chatMessages", JSON.stringify(chatHistory));
      }
      newMessages.push(
        ...response
          .filter(
            (msg) =>
              (msg.role === "user" || msg.role === "assistant") &&
              msg.content !== "error"
          ) // Only include user and assistant messages
          .map((msg) => ({
            id: msg.id,
            role: msg.role,
            type: msg.type,
            text: msg.content,
            timestamp: msg.timestamp,
          }))
      );

      chatHistory[0].messages.push(...newMessages);
      setMessages((prevMessages) => [...prevMessages, ...newMessages]);
      localStorage.setItem("chatMessages", JSON.stringify(chatHistory));

      setLoading(false);
      const lastMessage = response[response.length - 1]?.content;

      // If last message matches heavy traffic message, set loader to false
      if (
        lastMessage === "## Oops! Heavy traffic. Please try again in 5 minutes."
      ) {
        setLoading(false);
      }

      if (response[0].type === "human" || response[0].role === "human") {
        setUserStream((prevMessages) => [
          ...response.map((msg) => ({
            text: msg.messageUser,
          })),
        ]);
        googleCloudTTS("stop");
      }

      if (response && response[0] && response[0].questions) {
        setQuestions(response[0].questions);
        // Store the latest question in localStorage
        localStorage.setItem(
          "latestQuestion",
          JSON.stringify(response[0].questions)
        );
        setLoading(false);
      }

      const assistantMessage = response.find((msg) => msg.role === "assistant");
      if (response[0].role === "user") {
        setBotBusy(true);
      }
      if (response[0].role === "assistant") {
        setUserStream("");
        if (response[0].content !== "error") {
          const audio = new Audio(recieve);
          audio
            .play()
            .catch((error) => console.error("Error playing sound:", error));
        }
        setBotBusy(false);
        setTextFieldBusy(false);
      }

      if (assistantMessage && response[0].type === "call") {
        setUserStream("");
        setBotBusy(false);
        googleCloudTTS(assistantMessage.content);
      }
    });
        return () => {
      socket.off("botResponse");
    };
  }, []);

  // document.addEventListener("contextmenu", (event) => event.preventDefault());

  // // Disable DevTools Shortcuts
  // document.addEventListener("keydown", (event) => {
  //     if (
  //         event.key === "F12" ||
  //         (event.ctrlKey && event.shiftKey && (event.key === "I" || event.key === "J" || event.key === "C")) ||
  //         (event.ctrlKey && event.key === "U")
  //     ) {
  //         event.preventDefault();
  //     }
  // });
  useEffect(() => {
    const storedMessages = JSON.parse(localStorage.getItem("chatMessages"));
    if (storedMessages && storedMessages[0]?.messages.length > 0) {
      setStreamingState(true);
      setMessages(storedMessages[0].messages);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    setTextFieldBusy(true);
    if(educationMessages !== '') {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: "user",
          type: 'text',
          text: educationMessages,
          timestamp: new Date().toISOString(),
        },
      ]);
      let chatHistory = JSON.parse(localStorage.getItem("chatMessages")) || [];
          chatHistory[0].messages.push({
            role: "user",
            text: educationMessages,
            timestamp: new Date().toISOString(),
          });
     
        localStorage.setItem("chatMessages", JSON.stringify(chatHistory));

    }
  }, [educationMessages]);

  useEffect(() => {
    setLoading(false);
    setTextFieldBusy(false);

    if (BotResponseEducation !== '') {
        console.log('BotResponseEducation: ', BotResponseEducation);

        const degrees = Array.isArray(BotResponseEducation?.degrees) && BotResponseEducation.degrees.length > 0 
            ? BotResponseEducation.degrees.join(", ") 
            : undefined;  // Set degrees only if it exists

        setMessages((prevMessages) => [
            ...prevMessages,
            {
                role: "assistant",
                type: 'text',
                text: `## ${BotResponseEducation?.data || BotResponseEducation?.message}`,
                currentPage: BotResponseEducation?.currentPage,
                university: BotResponseEducation?.university,
                ...(degrees && { degrees }),  // Only include degrees if it exists
                totalPage: BotResponseEducation?.totalPages,
                timestamp: new Date().toISOString(),
            },
        ]);

        let chatHistory = JSON.parse(localStorage.getItem("chatMessages")) || [];
        chatHistory[0].messages.push({
            role: "assistant",
            text: BotResponseEducation?.data,
            currentPage: BotResponseEducation?.currentPage,
            university: BotResponseEducation?.university,
            ...(degrees && { degrees }),  // Only include degrees if it exists
            totalPage: BotResponseEducation?.totalPages,
            timestamp: new Date().toISOString(),
        });

        localStorage.setItem("chatMessages", JSON.stringify(chatHistory));
    }
}, [BotResponseEducation]);


  const latestMessageId = messages[messages.length - 1]?.id;

  return (
    <div
      className="chatbox shadow-lg mx-auto flex justify-center"
      style={{ background: "#0a0e18" }}
    >
      <div
        className="backdrop-blur-md bg-green bg-opacity-30 rounded-lg min-h-screen p-4 flex flex-col mt-4 main-container-all"
      >
        <Header visibleAvatar={visibleAvatar} isDivVisible={isDivVisible} />
        <div
          className="flex-grow overflow-y-auto scrollable-content"
          style={{
            display: "flex",
            flexDirection: "column", // Normal direction (top to bottom)
          }}
        >
          {isOnline ? (
            messages.map((message, index) => (
              <GoogleOAuthProvider clientId="121139969787-ohp35bh6k1g6dvl1h5pg9ep7c4gifc8g.apps.googleusercontent.com">

              <Messages
                key={index}
                callStatus={visibleAvatar}
                readTextOnClick={speakFunSpeaker}
                activeButtonState={activeButtonState}
                setActiveButtonState={setActiveButtonState}
                type={message?.type}
                messageId={message?.id}
                text={message?.text}
                role={message?.role}
                timestamp={message?.timestamp}
                visibleAvatar={visibleAvatar}
                streamingState={streamingState}
                checkSpeaker={checkSpeaker}
                setCheckSpeaker={setCheckSpeaker}
                latestMessageId={latestMessageId}
                setStreamingContinue={setStreamingContinue}
                onSearchJob={handleJobSearch}
                socket={socket}
                message={message}
              />
              </GoogleOAuthProvider>
            ))
          ) : (
            // Show offline message when offline
            <div
              className="flex items-center justify-center"
              style={{
                flexGrow: 1,
                textAlign: "center",
                color: "white",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              You are offline. Please connect to the internet.
            </div>
          )}
          {/* ###### */}
          {userStream.length > 0 && (
            <div
              className="flex mb-4 justify-end"
              style={{ justifyContent: "right", marginTop: "22px" }}
            >
              <div
                className="rounded-lg shadow-md bg-blue-500 text-white"
                style={{
                  maxWidth: "80%",
                  padding: "1rem",
                  borderRadius: "15px",
                  borderBottomRightRadius: "0px",
                  borderBottomLeftRadius: "15px",
                  backgroundColor: "#2d2d2d",
                  color: "#d5d5d5",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "14px",
                  lineHeight: "1.6",
                }}
              >
                <p
                  style={{
                    margin: 0,
                  }}
                >
                  {userStream[0]?.text}
                </p>
                {/* <div ref={messageEndRef} /> */}
              </div>
            </div>
          )}
          {loading && (
            <div className="loading-rotate">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          )}
          {/* ###### */}
          <p style={{ color: "white" }}>
            {/* {console.log('messagesTeeest: ', messages)} */}
          </p>
          <div ref={messageEndRef} /> {/* To keep focus at the end */}
        </div>
        <div style={{ marginTop: "auto" }}>
          {/* welcome messages */}
          {isOnline && messages?.length === 0 && (
            <div style={{ color: "white" }}>
              <h1
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              >
                Let's chat about life in Finland...
              </h1>
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                I can talk about universities, job market, setting up a
                business, social services...
              </p>

              {/* List of Questions */}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  justifyContent: "flex-start",
                  color: "white",
                }}
              >
                {[
                  "What are the top universities in Finland?",
                  "How can I find a job in Finland?",
                  "What are the steps to start a business in Finland?",
                  "How do I access social services?",
                ].map((question, index) => (
                  <div
                    key={index}
                    style={{
                      backgroundColor: "#1b1b1b",
                      fontSize: "12px",
                      border: "1px solid #363636",
                      color: "white",
                      padding: "8px 16px",
                      borderRadius: "8px",
                      cursor: "pointer",
                      transition: "background 0.3s ease",
                    }}
                    onClick={() => handleQuestionClick(question)}
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "#2c2c2c")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "#1b1b1b")
                    }
                  >
                    <span style={{ color: "white" }}>{question}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {isOnline && !loading && questions && (
            <div className="mt-1 mb-3">
              {" "}
              <div className="space-y-1">
                {" "}
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "6px",
                    justifyContent: "flex-end",
                  }}
                >
                  {questions.map((question, index) => (
                    <div
                      key={index}
                      style={{
                        backgroundColor: "#1b1b1b",
                        fontSize: "10px",
                        border: "1px solid #363636",
                        color: "white",
                        padding: "6px 12px",
                        borderRadius: "6px",
                        cursor: "pointer",
                        transition: "background 0.3s ease",
                      }}
                      onClick={() =>
                        handleQuestionClick(
                          question.question.replace(/[-*]/g, "").trim()
                        )
                      }
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundColor = "#2c2c2c")
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundColor = "#1b1b1b")
                      }
                    >
                      <span style={{ color: "white" }}>
                        {question.question.replace(/[-*]/g, "").trim()}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          <FormInput
            isOnline={isOnline}
            messages={messages}
            textValue={textValue}
            handleTextChange={handleTextChange}
            handleTextSubmit={handleTextSubmit}
            isTextFieldBusy={textFieldBusy}
            streamingContinue={streamingContinue}
            handleCallClick={handleCallClick}
            isCallActive={isCallActive}
            sendDataToParent={handleChildData}
            speakFunSpeaker={speakFunSpeaker}
          />
        </div>
        <p
          style={{
            fontSize: "14px",
            lineHeight: "1.6",
            marginBottom: "0px",
            textAlign: "center",
            color:"#414141",
          }}
        >
          Owned by Lincoln School | 3338513-8
        </p>
      </div>
      <audio ref={callStartRef}>
        <source src={callStart} type="audio/mp3" />
      </audio>
      <audio ref={callEndRef}>
        <source src={callEnd} type="audio/mp3" />
      </audio>
    </div>
  );
}

export default Chat;
