// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.glassmorphism {
    background: rgba(15, 15, 15, 0.2);  /* Semi-transparent background */
    backdrop-filter: blur(10px);           /* Blur effect for glassmorphism */
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;                   /* Rounded corners */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    border: 1px solid rgba(255, 255, 255, 0.3); /* Light border for the glassy look */
  }
  
  .shadow-lg {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3); /* Larger shadow for more emphasis */
  }`, "",{"version":3,"sources":["webpack://./src/Openchat/open.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC,GAAG,gCAAgC;IACpE,2BAA2B,YAAY,kCAAkC;IACzE,mCAAmC;IACnC,mBAAmB,oBAAoB,oBAAoB;IAC3D,yCAAyC,EAAE,kBAAkB;IAC7D,0CAA0C,EAAE,qCAAqC;EACnF;;EAEA;IACE,yCAAyC,EAAE,oCAAoC;EACjF","sourcesContent":[".glassmorphism {\n    background: rgba(15, 15, 15, 0.2);  /* Semi-transparent background */\n    backdrop-filter: blur(10px);           /* Blur effect for glassmorphism */\n    -webkit-backdrop-filter: blur(10px);\n    border-radius: 10px;                   /* Rounded corners */\n    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Subtle shadow */\n    border: 1px solid rgba(255, 255, 255, 0.3); /* Light border for the glassy look */\n  }\n  \n  .shadow-lg {\n    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3); /* Larger shadow for more emphasis */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
