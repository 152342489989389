import { useEffect, useState } from "react";
// import { useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWifi } from "@fortawesome/free-solid-svg-icons";
import Chat from "./Chat/Chat.js";

const App = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showOnlineMessage, setShowOnlineMessage] = useState(false);
//   const userName = useSelector((state) => state.auth);
// console.log("%%%%%%%%%%%%%% userName ", userName);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      setShowOnlineMessage(true);
      setTimeout(() => setShowOnlineMessage(false), 3000);
    };

    const handleOffline = () => {
      setIsOnline(false);
      setShowOnlineMessage(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        navigator.serviceWorker
          .register("/service-worker.js")
          .catch((err) => console.log("Service Worker registration failed", err));
      });
    }

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <div style={{ width: "100%", height: "auto" }}>
      {/* Offline Warning */}
      {!isOnline && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            backgroundColor: "rgb(20 24 34)",
            color: "white",
            textAlign: "center",
            padding: "10px",
            fontSize: "14px",
            fontWeight: "bold",
            zIndex: 9999,
          }}
        >
          <FontAwesomeIcon
            icon={faWifi}
            style={{ transform: "rotate(45deg)", marginRight: "5px" }}
          />
          No Internet. Check your connection.
        </div>
      )}

      {/* Online Notification */}
      {showOnlineMessage && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            backgroundColor: "rgb(0 167 131)",
            color: "white",
            textAlign: "center",
            padding: "10px",
            fontSize: "14px",
            fontWeight: "bold",
            zIndex: 9999,
          }}
        >
          <FontAwesomeIcon icon={faWifi} style={{ marginRight: "5px" }} />
          You're back online!
        </div>
      )}

      {/* Chat Component */}
      <Chat isOnline={isOnline} />
    </div>
  );
};

export default App;
