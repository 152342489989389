import React, { useEffect, useState, useRef } from "react";
import { marked } from "marked";

const Message = ({ type, text }) => {
  const [visible, setVisible] = useState(type === "user");
  const [displayedText, setDisplayedText] = useState("");
  const messageEndRef = useRef(null);
  const streamingSpeed = 20;

  // Ensure text is a valid string
  const safeText = typeof text === 'string' ? text : '';

  // Clean up the text if it contains "no information available" placeholder
  const processedText = safeText.includes("There is no information available regarding")
    ? "I'm sorry, I couldn't find the information you're looking for. Please feel free to reach out directly at Email: contact@TalentBridge.fi or Phone: +358 40 571 0112 for more assistance."
    : safeText;

  useEffect(() => {
    if (type === "bot" && processedText) {
      setVisible(true);
      setDisplayedText("");
      let currentIndex = -1;
      let fullText = processedText;

      const intervalId = setInterval(() => {
        if (currentIndex < fullText.length) {
          setDisplayedText((prevText) => {
            const nextChar = fullText[currentIndex];
            return nextChar ? prevText + nextChar : prevText;
          });
          currentIndex++;
          if (messageEndRef.current) {
            messageEndRef.current.scrollIntoView({ behavior: "smooth" });
          }
        } else {
          clearInterval(intervalId);
        }
      }, streamingSpeed);

      return () => {
        clearInterval(intervalId);
      };
    } else {
      setDisplayedText(processedText);
    }
  }, [type, processedText]);

  // Convert Markdown to HTML and set links to open in a new tab
  const formattedText = marked(displayedText, {
    renderer: new marked.Renderer(),
    gfm: true, // Use GitHub-flavored Markdown for better formatting support
  });

  // Modify all links to open in a new tab and apply link color
  const styledText = formattedText.replace(
    /<a href="(.*?)">(.*?)<\/a>/g,
    '<a href="$1" target="_blank" rel="noopener noreferrer" style="color:blue;text-decoration:underline;">$2</a>'
  );

  // Don't render if there's no text to display
  if (!visible || !processedText) return null;

  return (
    safeText !== 'error' &&
    <div
      className={`flex mb-4 ${type === "user" ? "justify-end" : "justify-start"}`}
      style={{ justifyContent: type === "user" ? "right" : "left" }}
    >
      <div
        className={`rounded-lg shadow-md ${
          type === "user" ? "bg-blue-500 text-white" : "bg-gray-100 text-black"
        }`}
        style={{
          width: "70%",
          padding: "0.5rem 1rem",
          borderRadius: "15px",
          borderBottomRightRadius: type === "user" ? "0px" : "15px",
          borderBottomLeftRadius: type === "bot" ? "0px" : "15px",
          backgroundColor: type === "user" ? "#1a73e8" : "#f3f1f1",
          color: type === "user" ? "white" : "black",
        }}
      >
        {type === "bot" ? (
          // Render the parsed HTML with modified links
          <div dangerouslySetInnerHTML={{ __html: styledText }} />
        ) : (
          <p>{safeText}</p>
        )}
        <div ref={messageEndRef} />
      </div>
    </div>
  );
};

export default Message;
