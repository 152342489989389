import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import callStart from "../assets/Audio/call-start.mp3";
import callEnd from "../assets/Audio/call-end.mp3";
import "./chat.css";
import Header from "../Chat/Header";
import Messages from "../Chat/Messages";
import FormInput from "../Chat/FormInput";
import socket from "./socket";

function Chat(props) {
  const [botBusy, setBotBusy] = useState(false);
  const [textFieldBusy, setTextFieldBusy] = useState(false);

  const audioInstancesRef = useRef([]);
  const [isDivVisible, setDivVisible] = useState(false);
  const [visibleAvatar, setVisibleAvatar] = useState(false);
  const [isCallActive, setIsCallActive] = useState(false);
  const [messages, setMessages] = useState([
    { type: "bot", text: "Hello! I am SUSAN, How can I help you today?" },
  ]);
  const [textValue, setTextValue] = useState("");
  const audioInstanceRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const callStartRef = useRef(null);
  const callEndRef = useRef(null);
  const timeoutRef = useRef(null);
  const messageEndRef = useRef(null);

  const googleCloudTTS = async (text) => {
    // Stop and clear any currently playing audio
    if (audioInstancesRef.current.length > 0) {
      audioInstancesRef.current.forEach((audio) => {
        if (audio) {
          audio.pause();
          audio.currentTime = 0;
          audio.src = "";
        }
      });
      audioInstancesRef.current = [];
    }
  
    const cleanedText = text
      .replace(/<[^>]+>/g, "")
      .replace(/\*\*|__|~~/g, "")
      .replace(/https?:\/\/\S+/g, "")
      .replace(/\[.*?\]\(.*?\)/g, "")
      .replace(/#{1,}/g, "");
  
    const apiKey = "AIzaSyBs_GzMmIyN-MOFUnOVCiPGetlF0aCLgIM";
    const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`;
    const config = {
      input: { text: cleanedText },
      voice: { languageCode: "en-US", name: "en-US-Studio-O" },
      audioConfig: { audioEncoding: "MP3" },
    };
  
    try {
      const response = await axios.post(url, config);
      const audioContent = response.data.audioContent;
  
      if (audioContent) {
        const audio = new Audio(`data:audio/mp3;base64,${audioContent}`);
  
        // Stop any previous audio
        if (audioInstanceRef.current) {
          audioInstanceRef.current.pause();
          audioInstanceRef.current.currentTime = 0;
          audioInstanceRef.current.src = "";
        }
  
        audioInstanceRef.current = audio;
        audioInstancesRef.current.push(audio);
  
        audio.play();
  
        // Start monitoring for user speech
        monitorUserSpeech(audio);
  
        audio.onended = () => {
          console.log("Audio playback finished");
          const index = audioInstancesRef.current.indexOf(audio);
          if (index > -1) audioInstancesRef.current.splice(index, 1);
          audioInstanceRef.current = null;
        };
      }
    } catch (error) {
      console.error("Error calling Google Cloud TTS:", error);
    }
  };
  
  const monitorUserSpeech = (audio) => {
    navigator.mediaDevices
      .getUserMedia({
        audio: {
          echoCancellation: true, // Reduce feedback from speakers
          noiseSuppression: true, // Suppress background noise
          autoGainControl: true, // Adjust mic sensitivity automatically
        },
      })
      .then((stream) => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const microphone = audioContext.createMediaStreamSource(stream);
        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 2048; // Higher FFT size for better frequency resolution
        microphone.connect(analyser);
  
        const dataArray = new Uint8Array(analyser.frequencyBinCount);
        const speechFrequencyRange = [85, 255]; // Human speech frequency range (Hz)
        const volumeThreshold = 30; // Minimum volume for speech
        const sustainedDuration = 2500; // Require 2 seconds of sustained speech
        const detectionInterval = 100; // Check every 100ms
  
        let speechStartTime = null; // Time when sustained speech starts
        let lastDetectionTime = Date.now(); // Last time speech was detected
  
        const detectSpeech = () => {
          analyser.getByteFrequencyData(dataArray); // Get frequency data
  
          // Check if audio matches human speech frequency range
          const isSpeechFrequency = dataArray.some((value, index) => {
            const frequency = (audioContext.sampleRate / analyser.fftSize) * index;
            return frequency >= speechFrequencyRange[0] && frequency <= speechFrequencyRange[1] && value > volumeThreshold;
          });
  
          const now = Date.now();
  
          if (isSpeechFrequency) {
            // Start or continue tracking sustained speech
            if (!speechStartTime) {
              speechStartTime = now; // Start tracking speech
            }
  
            // Check if sustained speech duration exceeds threshold
            if (now - speechStartTime >= sustainedDuration) {
              console.log("Human speech detected for 2 seconds, stopping bot audio...");
              audio.pause();
              audio.src = ""; // Clear the audio source
  
              // Clean up resources
              stream.getTracks().forEach((track) => track.stop());
              analyser.disconnect();
              microphone.disconnect();
              clearInterval(intervalId);
            }
          } else {
            // Reset speech tracking if no speech detected for a short time
            if (now - lastDetectionTime > detectionInterval) {
              speechStartTime = null;
            }
          }
  
          // Update the last detection time
          lastDetectionTime = now;
        };
  
        // Run speech detection every 100ms
        const intervalId = setInterval(detectSpeech, detectionInterval);
  
        // Cleanup when audio ends
        audio.onended = () => {
          clearInterval(intervalId);
          stream.getTracks().forEach((track) => track.stop());
          analyser.disconnect();
          microphone.disconnect();
        };
  
        // Cleanup when audio is manually paused
        audio.onpause = () => {
          clearInterval(intervalId);
          stream.getTracks().forEach((track) => track.stop());
          analyser.disconnect();
          microphone.disconnect();
        };
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });
  };
  
  
  const startCall = async () => {
    setVisibleAvatar(true);
    googleCloudTTS("Hello! I am Susan, how can I help you today?");
    socket.emit("startTranscription");
    setIsCallActive(true);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "audio/webm;codecs=opus",
      });

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          socket.emit("audioChunk", event.data);
        }
      };

      mediaRecorderRef.current.start(300);
    } catch (error) {
      console.error("Error accessing microphone:", error);
      setBotBusy(false);
    }
  };

  const handleTextChange = (event) => {
    setTextValue(event.target.value);
  };

  const handleTextSubmit = (event) => {
    setTextFieldBusy(true);
    setIsCallActive(false);
    setMessages((prevMessages) => [
      ...prevMessages,
      { type: "user", text: textValue },
    ]);
    socket.emit("userMessage", textValue);
    setTextValue("");
  };

  const endCall = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
      setDivVisible(false);
    }

    setVisibleAvatar(false);
    setIsCallActive(false);

    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
      mediaRecorderRef.current = null;
    }

    audioInstancesRef.current.forEach((audio) => {
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    });
    audioInstancesRef.current.length = 0;

    if (callStartRef.current) {
      callStartRef.current.pause();
      callStartRef.current.currentTime = 0;
    }

    if (callEndRef.current) {
      callEndRef.current.currentTime = 2;
      callEndRef.current.loop = false;
      callEndRef.current.play();
    }

    setTimeout(() => {
      if (callEndRef.current) {
        callEndRef.current.pause();
        callEndRef.current.currentTime = 0;
      }
    }, 2000);
  };

  const handleCallClick = async () => {
    if (isCallActive) {
      endCall();
      return;
    }

    setDivVisible(true);
    try {
      await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
      });

      if (callStartRef.current) {
        callStartRef.current.currentTime = 7;
        callStartRef.current.loop = true;
        callStartRef.current.play();
      }

      timeoutRef.current = setTimeout(() => {
        setDivVisible(false);
        if (callStartRef.current) {
          callStartRef.current.pause();
          callStartRef.current.currentTime = 0;
        }
        startCall();
      }, 5000);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    socket.on("botResponse", (response) => {
      setMessages((prevMessages) => [
        ...prevMessages,
        ...response.map((msg) => ({
          type: msg.role === "assistant" ? "bot" : "user",
          text: msg.content,
        })),
      ]);

      const assistantMessage = response.find((msg) => msg.role === "assistant");
      console.log("assistantMessage: ", assistantMessage);
      // setIsCallActive(true)
      console.log("isCallActive Cheeck: ", response);
      
      
      if (response[0].role === "user") {
        setBotBusy(true)
      }
      if (response[0].role === "assistant") {
        setBotBusy(false)

        setTextFieldBusy(false)
      }
      // setTextFieldBusy

      if (assistantMessage && response[0].type === "call") {
        setBotBusy(false)
        googleCloudTTS(assistantMessage.content);
      }
    });

    return () => {
      socket.off("botResponse");
    };
  }, []);

  return (
    <div className="chatbox bg-white shadow-lg">
      <div className="backdrop-blur-md bg-green bg-opacity-30 rounded-lg h-full p-4 flex flex-col">
        <Header
          visibleAvatar={visibleAvatar}
          isDivVisible={isDivVisible}
          Close={props.Close}
        />
        <div className="flex-grow overflow-y-auto scrollable-content">
          {messages.map((message, index) => (
            <Messages key={index} type={message?.type} text={message?.text} />
          ))}
          <div ref={messageEndRef} />
        </div>
        <FormInput
          textValue={textValue}
          handleTextChange={handleTextChange}
          handleTextSubmit={handleTextSubmit}
          isTextFieldBusy={textFieldBusy}
          handleCallClick={handleCallClick}
          isCallActive={isCallActive}
        />
      </div>
      <audio ref={callStartRef}>
        <source src={callStart} type="audio/mp3" />
      </audio>
      <audio ref={callEndRef}>
        <source src={callEnd} type="audio/mp3" />
      </audio>
    </div>
  );
}

export default Chat;
